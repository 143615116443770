body{
  @apply bg-dark;
}
#root{
  @apply bg-dark text-white;
  min-height: 100vh;
  min-height: 100svh;
  overflow-x: hidden
}
.main-content{
  min-height: 100vh;
  min-height: 100svh;
}

// !!!!!!!!!!!!!!!!!!
// hide play button icon on iOS
// !!!!!!!!!!!!!!!!!!

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
