.section{
  width: 100%;
  padding-top: clamp-calc(map-get($screens, xs), map-get($screens, sm), 1rem, 2rem);
  padding-bottom: clamp-calc(map-get($screens, xs), map-get($screens, sm), 1rem, 2rem);
}
.section-sm{
  width: 100%;
  padding-top: clamp-calc(map-get($screens, xs), map-get($screens, sm), 0.5rem, 1.5rem);
  padding-bottom: clamp-calc(map-get($screens, xs), map-get($screens, sm), 0.5rem, 1.5rem);
}
