.btn{
  @apply bg-white text-dark font-medium drop-shadow-hotpink-sm;;
  position: relative;
  > span{
    display: block;
    position: relative;
    z-index: 1;
    padding: 0.7rem 1.8rem;
    background: white;
    z-index: 2;
  }
}
