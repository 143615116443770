@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
.section-loader{
  min-width: 200px;
  width: 33.33%;
  height: 200px;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: theme('colors.mid');
  cursor: pointer;
  transition: 0.3s linear;
  margin-left: auto;
  margin-right: auto;
  .loader{
    width: 48px;
    height: 48px;
    border: 3px solid theme('colors.mid');
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation: rotation 1s linear infinite;
    &:after{
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 56px;
      height: 56px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-bottom-color: theme('colors.hotpink');
    }
  }
}
