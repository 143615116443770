.swiper-pagination{
  @apply my-6 sm:my-12;
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 124px;
  gap: 5%;
  width: 100%;
  .swiper-pagination-bullet{
    background-color: rgba(#FFF, 0.4);
    transition: background-color 0.5s ease-in-out;
    height: 2px;
    display: block;
    flex-grow: 1;
    &.swiper-pagination-bullet-active{
      background-color: #FFF;
    }
  }
}
