@font-face {
  font-family: 'Blender';
  src: url('../../fonts/blender-heavy-webfont.woff2') format('woff2'),
    url('../../fonts/blender-heavy-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Blender';
  src: url('../../fonts/blender-bold-webfont.woff2') format('woff2'),
    url('../../fonts/blender-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Blender';
  src: url('../../fonts/blender-medium-webfont.woff2') format('woff2'),
    url('../../fonts/blender-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Blender';
  src: url('../../fonts/blender-book-webfont.woff2') format('woff2'),
    url('../../fonts/blender-book-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

body{
  @apply font-body;
	line-height: 1.45;
}
.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5{
	@apply font-heading;
	text-transform: uppercase;
	line-height: 0.8;
  &.\!normal-case{
    line-height: 1;
  }
}

.text {
	&-h1 {
		font-size: clamp-calc(map-get($screens, xs), map-get($screens, sm), map-get($fontSizes, 2xl), map-get($fontSizes, 4xl));
	}
	&-h2 {
		font-size: clamp-calc(map-get($screens, xs), map-get($screens, sm), map-get($fontSizes, xl), map-get($fontSizes, 3xl));
	}
	&-h3 {
		font-size: clamp-calc(map-get($screens, xs), map-get($screens, sm), map-get($fontSizes, lg), map-get($fontSizes, 2xl));
	}
	&-h4 {
		font-size: clamp-calc(map-get($screens, xs), map-get($screens, sm), map-get($fontSizes, md), map-get($fontSizes, xl));
	}
	&-h5 {
		font-size: clamp-calc(map-get($screens, xs), map-get($screens, sm), map-get($fontSizes, md), map-get($fontSizes, lg));
	}
}
.text-2xl{
	font-size: clamp-calc(map-get($screens, xs), map-get($screens, sm), map-get($fontSizes, lg), map-get($fontSizes, 2xl));
	line-height: 1.25;
}
.text-xl{
	font-size: clamp-calc(map-get($screens, xs), map-get($screens, sm), map-get($fontSizes, md), map-get($fontSizes, xl));
	line-height: 1.3;
}
.text-lg{
	font-size: clamp-calc(map-get($screens, xs), map-get($screens, sm), map-get($fontSizes, base), map-get($fontSizes, lg));
	line-height: 1.35;
}
.text-md{
	font-size: clamp-calc(map-get($screens, xs), map-get($screens, sm), map-get($fontSizes, base), map-get($fontSizes, md));
	line-height: 1.4;
}

.text-shadow-hotpink-sky{
  display: grid;
  span{
    position: relative;
    z-index: 2;
  }
  span, &:before, &:after{
    grid-column: 1;
    grid-row: 1;
  }
  &:before, &:after{
    content: attr(data-content);
    position: relative;
    z-index: 0;
  }
  &:before{
    @apply text-hotpink;
    left: $chromaticOffsetX;
    top: $chromaticOffsetY;
    transform: rotate($chromaticRotate);
    transform-origin: 100% 100%;
  }
  &:after{
    @apply text-sky;
    right: $chromaticOffsetX;
    bottom: $chromaticOffsetY;
    transform: rotate($chromaticRotate);
    transform-origin: 0% 0%;
  }
  // display: grid;
  // span{
  //   position: relative;
  //   z-index: 2;
  //   grid-column: 1;
  //   grid-row: 1;
  // }
  // .text-shadow-hotpink-sky-clone-sky, .text-shadow-hotpink-sky-clone-hotpink{
  //   z-index: 0;
  // }
  // .text-shadow-hotpink-sky-clone-hotpink{
  //   @apply text-hotpink;
  //   // left: -1px;
  // }
  // .text-shadow-hotpink-sky-clone-sky{
  //   @apply text-sky;
  // }
}

.text-stroke{
  -webkit-text-fill-color: #080a0b;
  -webkit-text-stroke: 1px currentcolor;
}
