.container{
  padding-left: clamp-calc(map-get($screens, xs), map-get($screens, sm), 1.3rem, 3rem);
  padding-right: clamp-calc(map-get($screens, xs), map-get($screens, sm), 1.3rem, 3rem);
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.container-expand{
  max-width: none;
}
