// keeping everythign in rem for simplicity

// breakpoints sm - 2xl are Tailwind's default values
// would be preferable to keep them as they are to maintain consistency
$screens: (
  xs: 20rem,         // 320px
  sm: 27.5rem,        // 440px
  md: 40rem,           // 640px
  // md: 48rem,           // 768px
  // lg: 64rem,           // 1024px
  // xl: 80rem,           // 1280px
  // 2xl: 90rem,          // 1440px
  // 3xl: 120rem,         // 1920px
);

$fontSizes: (
  sm: 0.875rem,       // 14
  base: 1rem,         // 16
  md: 1.1875rem,      // 19
  lg: 1.375rem,       // 22
  xl: 1.625rem,       // 26
  2xl: 2.1875rem,     // 35
  3xl: 2.5rem,        // 40
  4xl: 5.9375rem,     // 95
);

// easing timing functions - https://matthewlein.com/tools/ceaser
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);

// text shadow/offset effect
$chromaticOffsetX: -4px;
$chromaticOffsetY: -1px;
$chromaticRotate: 1.2deg;
