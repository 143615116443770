$shimmy-distance: 5;
$clip: polygon(0 0, 100% 0, 100% 100%, 0% 100%);;
$clip-one: polygon(30% 2%, 100% 2%, 100% 95%, 68.01% 95%, 68.01% 50.65%, 52.08% 50.44%, 51.5% 95%, 30% 95%);
$clip-two: polygon(0 44%, 100% 44%, 100% 54%, 95% 54%, 95% 54%, 85% 54%, 85% 54%, 8% 54%, 0 54%);
$clip-three: polygon(0 0, 100% 0, 100% 0, 95% 0, 95% 0, 85% 0, 85% 0, 8% 0, 0 0);
$clip-four: polygon(0% 40%, 100% 40%, 100% 85%, 95% 85%, 39.75% 85%, 39.75% 53%, 0% 53%);
$clip-five: polygon(36.25% 0%, 69% 0%, 69% 63%, 100% 63%, 100% 100%, 35.7% 100%, 36.25% 80%, 0% 80%, 0% 63%, 36.25% 63%);

$artifact-clip-one: polygon(0% 60.5%, 46% 60.5%, 46% 65%, 0% 65%);
$artifact-clip-two: polygon(77% 24%, 83.5% 24%, 83.5% 33.32%, 77% 33.32%);
$artifact-clip-three: polygon(44% 84%, 100% 84%, 100% 91%, 44% 91%);
$artifact-clip-four: polygon(16.2% 27.76%, 90% 27.76%, 90% 31.5%, 16.2% 31.5%);
$artifact-clip-five: polygon(78.75% 77%, 78.75% 80.75%, 15.25% 80.75%, 15.25% 77%);

/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
Background colour flashes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
@keyframes flash-bg-amethyst {
  0%, 100% {
    background: transparent;
  }
  50%{
    background: theme('colors.amethyst');
  }
}
@keyframes flash-bg-flamingo {
  0%, 100% {
    background: transparent;
  }
  50%{
    background: theme('colors.flamingo');
  }
}
@keyframes flash-bg-carnation {
  0%, 100% {
    background: transparent;
  }
  50%{
    background: theme('colors.carnation');
  }
}
/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
Glitch 1
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
@keyframes glitch-1 {
  0% {
    clip-path: $clip;
  }
  9% {
    clip-path: $clip;
  }
  10% {
    clip-path: $clip-one;
    transform: translate(0, 0);
  }
  12% {
    clip-path: $clip-one;
    transform: translate(calc($shimmy-distance * -1%), 0);
  }
  13%, 48% {
    clip-path: $clip;
    transform: translate(0, 0);
  }
  // 51% {
  //   clip-path: $clip-two;
  //   transform: translate(calc($shimmy-distance * -1%), 0);
  // }
  // 53% {
  //   clip-path: $clip-two;
  //   transform: translate(calc($shimmy-distance * 1%), 0);
  // }
  // 53%, 61%, 65% {
  //   clip-path: $clip;
  //   transform: translate(0, 0);
  // }
  // 62% {
  //   clip-path: $clip-three;
  //   transform: translate(calc($shimmy-distance * 1%), 0);
  // }
  // 64% {
  //   clip-path: $clip-three;
  //   transform: translate(0, 0);
  // }
  // 79% {
  //   clip-path: $clip;
  // }
  // 80% {
  //   clip-path: $clip-two
  // }
  // 83% {
  //   clip-path: $clip-two
  // }
  // 84% {
  //   clip-path: $clip
  // }
}
.ani-glitch-1{
  animation: glitch-1 4s infinite;
}
/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
Glitch 2
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
@keyframes glitch-2 {
  // 0%, 16.5%, 18.5% {
  //   clip-path: $clip;
  //   transform: translate(0, 0);

  // }
  // 17% {
  //   clip-path: $clip-four;
  //   transform: translate(calc($shimmy-distance * 1%), 0);
  // }
  // 18% {
  //   clip-path: $clip-four;
  //   transform: translate(calc($shimmy-distance * 2%), 0);
  // }
  33%, 34% {
    clip-path: $clip;
    transform: translate(0, 0);
  }
  33.5% {
    clip-path: $clip-five;
    transform: translate(calc($shimmy-distance * -2%), 0);
  }
  38%, 39% {
    clip-path: $clip;
    transform: translate(0, 0);
  }
  38.5% {
    clip-path: $clip-two;
    transform: translate(calc($shimmy-distance * -2%), 0);
  }
}
.ani-glitch-2{
  animation: glitch-2 6s infinite;
}

/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
Artifact Glitch 1
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
@keyframes artifact-glitch-1 {
  0% {
    opacity: 0;
  }
  11%{
    opacity: 0;
    clip-path: $artifact-clip-one;
  }
  12%{
    opacity: 1;
    clip-path: $artifact-clip-one;
    transform: translate(0, 0);
  }
  13%{
    opacity: 1;
    clip-path: $artifact-clip-one;
    transform: translate(calc($shimmy-distance * 1%), 0);
  }
  14%{
    opacity: 0;
    clip-path: $artifact-clip-one;
    transform: translate(0, 0);
  }
  20%{
    opacity: 0;
    clip-path: $artifact-clip-two;
    transform: translate(0, calc($shimmy-distance * 0%));
  }
  22%{
    opacity: 1;
    clip-path: $artifact-clip-two;
    transform: translate(0, calc($shimmy-distance * 0.3%));
  }
  23%{
    opacity: 1;
    clip-path: $artifact-clip-two;
    transform: translate(0, calc($shimmy-distance * -1.5%));
  }
  24%{
    opacity: 0;
    clip-path: $artifact-clip-two;
  }
  68%{
    opacity: 0;
    clip-path: $artifact-clip-three
  }
  69%{
    opacity: 1;
    clip-path: $artifact-clip-three
  }
  72%{
    opacity: 1;
    clip-path: $artifact-clip-three
  }
  73%{
    opacity: 0;
    clip-path: $artifact-clip-three
  }
  90%{
    opacity: 0;
    clip-path: $artifact-clip-four;
  }
  91%{
    opacity: 1;
    clip-path: $artifact-clip-four;
    transform: translate(0, 0);
  }
  92%{
    opacity: 1;
    clip-path: $artifact-clip-four;
    transform: translate(calc($shimmy-distance * 1%), 0);
  }
  93%{
    opacity: 0;
    clip-path: $artifact-clip-four;
    transform: translate(0, 0);
  }
  100%{
    opacity: 0;
    clip-path: $clip;
    transform: translate(0, 0);
  }
}
.ani-artifact-glitch-1{
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: currentColor;
  opacity: 0;
  animation: artifact-glitch-1 4.3s infinite forwards;
}

/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
Glitch split 1 & 2
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
@keyframes glitch-split-1 {
  45%, 39%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  40% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 54%, 0% 54%);
    transform: translate(-35%, 0)  skew(-20deg);
  }
  41%{
    transform: translate(40%, 0);
  }
  73%, 69%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  72% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 48.5%, 0% 48.5%);
    transform: translate(-55%, 0) skew(-35deg);
  }
}
@keyframes glitch-split-2 {
  45%, 39%{
    clip: $clip;
    transform: translate(0, 0);
  }
  40% {
    clip-path: polygon(0% 54%, 100% 54%, 100% 100%, 0% 100%);
    transform: translate(60%, 0) skew(-30deg);
  }
  41%{
    transform: translate(-40%, 0);
  }
  73%, 69%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  72% {
    clip-path: polygon(0% 56%, 100% 56%, 100% 100%, 0% 100%);
    transform: translate(-15%, 0)  skew(10deg);
  }
}
.ani-glitch-split-1{
  animation: glitch-split-1 2.3s infinite forwards;
}
.ani-glitch-split-2{
  animation: glitch-split-2 2.3s infinite forwards;
}

/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
Glitch split 3 & 4
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
@keyframes glitch-split-3 {
  41%, 39.4%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  40% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 54%, 0% 54%);
    transform: translate(-8%, 0);
  }
  40.3%{
    transform: translate(10%, 0);
  }
  52.6%, 51.6%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  52% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 48.5%, 0% 48.5%);
    transform: translate(-20%, 0) skew(-27deg);
  }
}
@keyframes glitch-split-4 {
  41%, 39.4%{
    clip: $clip;
    transform: translate(0, 0);
  }
  40% {
    clip-path: polygon(0% 54%, 100% 54%, 100% 100%, 0% 100%);
    transform: translate(30%, 0) skew(-30deg);
  }
  40.3%{
    transform: translate(-10%, 0);
  }
  52.6%, 51.6%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  52% {
    clip-path: polygon(0% 56%, 100% 56%, 100% 100%, 0% 100%);
    transform: translate(-15%, 0)  skew(10deg);
  }
}

.ani-glitch-split-3{
  animation: glitch-split-3 4s infinite forwards;
}
.ani-glitch-split-4{
  animation: glitch-split-4 4s infinite forwards;
}
/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
Glitch split 5 & 6
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
@keyframes glitch-split-5 {
  10.6%, 9.2%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  10% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 59% 100%, 59% 41.67%, 0% 41.67%);
    transform: translate(-12%, 0) skew(-8deg);
  }
  82.3%, 81.3%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  82% {
    clip-path: polygon(57% 100%, 0% 100%, 0% 0%, 56.75% 0%, 56.75% 40.75%, 85.5% 40.75%, 85.5% 77.23%, 56.75% 77.23%);
    transform: translate(20%, 0) skew(-27deg);
  }
}
@keyframes glitch-split-6 {
  9.2%{
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transform: translate(0, 0);
  }
  10% {
    clip-path: polygon(0% 41.78%, 58.22% 41.5%, 58.5% 100%, 0% 100%);
    transform: translate(30%, 0);
  }
  10.6% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transform: translate(0, 0);
  }
  81.3%, 82.3%{
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transform: translate(0, 0);
  }
  82% {
    clip-path: polygon(0% 41.78%, 58.22% 41.5%, 58.5% 100%, 0% 100%);
    transform: translate(-60%, 0) skew(10deg);
  }
}

.ani-glitch-split-5{
  animation: glitch-split-5 10s infinite forwards;
}
.ani-glitch-split-6{
  animation: glitch-split-6 10s infinite forwards;
}
/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
Glitch split 7 & 8
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
@keyframes glitch-split-7 {
  20%, 40%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  30% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 54%, 0% 54%);
    transform: translate(-38%, 0) skew(20deg);
  }
  34%{
    transform: translate(30%, 0);
  }
  45%, 55%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  51% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 48.5%, 0% 48.5%);
    transform: translate(-40%, 0) skew(-47deg);
  }
  65%, 60%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  63% {
    clip-path: polygon(57% 100%, 100% 100%, 100% 0%, 56.75% 0%, 56.75% 40.75%, 85.5% 40.75%, 85.5% 77.23%, 56.75% 77.23%);
    transform: translate(-60%, 0)  skew(30deg);
  }
  81.3%, 82.3%{
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transform: translate(0, 0);
  }
  82% {
    clip-path: polygon(0% 41.78%, 58.22% 41.5%, 58.5% 100%, 0% 100%);
    transform: translate(-60%, 0) skew(10deg);
  }
}
@keyframes glitch-split-8 {
  10%, 40%{
    clip: $clip;
    transform: translate(0, 0);
  }
  20% {
    clip-path: polygon(0% 54%, 100% 54%, 100% 100%, 0% 100%);
    transform: translate(30%, 0) skew(-30deg);
  }
  28%{
    transform: translate(-10%, 0);
  }
  45%, 55%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  51% {
    clip-path: polygon(0% 56%, 100% 56%, 100% 100%, 0% 100%);
    transform: translate(-25%, 0)  skew(20deg);
  }
  65%, 60%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  63% {
    clip-path: polygon(57% 100%, 0% 100%, 0% 0%, 56.75% 0%, 56.75% 40.75%, 85.5% 40.75%, 85.5% 77.23%, 56.75% 77.23%);
    transform: translate(50%, 0) skew(-27deg);
  }
  82.3%, 81.3%{
    clip-path: $clip;
    transform: translate(0, 0);
  }
  82% {
    clip-path: polygon(57% 100%, 0% 100%, 0% 0%, 56.75% 0%, 56.75% 40.75%, 85.5% 40.75%, 85.5% 77.23%, 56.75% 77.23%);
    transform: translate(20%, 0) skew(-27deg);
  }
}

.ani-glitch-split-7{
  animation: glitch-split-7 0.9s infinite;
}
.ani-glitch-split-8{
  animation: glitch-split-8 0.9s infinite;
}

/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
Button glitch
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
.btn-glitch{
  [class^="ani-glitch-split"]{
    animation-duration: 10s;
  }
}
.animation-duration-5s{
    animation-duration: 5s;
}
.animation-duration-10s{
  animation-duration: 10s;
}

// @keyframes glitch-reveal-1{
//   0%{
//     clip-path: polygon(0% 19.95%, 0% 19.95%, 0% 0%, 80% 0%, 0% 0%, 0% 19.95%, 0% 40%, 0% 63.25%, 0% 100%, 0% 100%, 0% 63.25%, 0% 63.25%, 0% 40.1%, 0% 40%);
//   }
//   20%{
//     clip-path: polygon(8.25% 19.95%, 16.58% 19.95%, 16.58% 0%, 8.25% 0%, 0% 0%, 0% 19.95%, 0% 40%, 0% 63.25%, 0% 100%, 9.41% 100%, 9.41% 63.25%, 16.58% 63.25%, 16.58% 40.1%, 8.25% 40%);
//   }
//   60%{
//     clip-path: polygon(25.67% 19.6%, 50% 20%, 50.33% 0%, 25.17% 0%, 0% 0%, 0% 19.95%, 0% 40%, 0% 63.25%, 0% 100%, 9.41% 100%, 9.41% 63.25%, 100% 63.25%, 100% 40%, 26.2% 40%);
//   }
//   100%{
//     clip-path: polygon(100% 20%, 100% 19.6%, 100% 0%, 25.17% 0%, 0% 0%, 0% 19.95%, 0% 40%, 0% 63.25%, 0% 100%, 100% 100%, 100% 63.25%, 100% 63.25%, 100% 40%, 100% 40.25%);
//   }
// }

